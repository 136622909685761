import React from 'react'

import Layout from '../components/layout'
import {Instalment} from '../components/instalment'
import Seo from '../components/seo'



const LodzInstalment = () => (
  <Layout lodz>
    <Seo
      title="O nas - klinika modelowania sylwetki i kosmetologii estetycznej"
      description="Wyjątkowe miejsce na beauty mapie Łodzi. Sprawdź ofertę zabiegów zabiegów w klinice BodyMed Group Łódź." 
      url="https://bodymedgroup.pl/lodz-o-nas"
    />
   <Instalment/>
  </Layout>
)

export default LodzInstalment

import React from 'react'
import styled from 'styled-components'
import { StaticImage } from "gatsby-plugin-image"


import { Wrapper } from '../common/wrapper'
import { Container } from '../common/container'
import { Heading } from '../common/heading'
import { StyledLink } from '../common/link'

import { device } from '../../utils/breakpoints'


const StyledWrapper = styled(Wrapper) `
    background: ${({ theme }) => theme.colors.grayWhite};
    padding: 3rem 5vw;
`

const StyledContainer = styled(Container) `
    display: grid;
    gap: 2rem;

`
const ContainerText = styled.div `
    display: grid;
    grid-template-rows: auto;
    gap: 1rem;  
    max-width: 700px;

`

const ContainerInstalments = styled.div `
    display: grid;
    grid-template-columns: auto;
    gap: 2rem;
    justify-content: start;

    @media ${device.laptop} {
        grid-template-columns: 1fr 1fr;
    }


`
const ContainerInstalment = styled.div `
    display: grid;
    justify-items: start;
    align-items: start;
    gap: 2rem;
    max-width: 700px;

    ol {
        list-style: revert;
        display: grid;
        gap: 0.5rem;

        li {
            line-height: 120%;
        }
    }


`

const ContainerInstalmentText = styled.div `
    display: grid;
    grid-template-rows: auto;
    gap: 1rem;  

    ul {
        list-style: inherit;
        display: grid;
        gap: 0.5rem;  
        
        li {
            line-height: 120%;
        }
    }
`
const ContainerListsInstalment = styled.div `
    display: grid;
    grid-template-rows: repeat(2, auto);
    gap: 2rem;
`   

const StyledButton = styled.a `
    padding: 0.8rem 1.5rem;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    white-space: nowrap;
    background: ${({isGreen}) => isGreen ? '#2D6A4F' : '#fff'};  
    color: ${({isGreen}) => isGreen ? '#fff' : '#2D6A4F'};  
    border: 1px solid #2D6A4F;
    cursor: pointer;
    font-size: ${({theme}) => theme.fonts.size.xs};
    text-transform: none;
    text-align: center;

    @media ${device.tabletXL} {

        :hover {
            background: ${({isGreen}) => isGreen ? '#fff' : '#2D6A4F'};  
            color: ${({isGreen}) => isGreen ? '#2D6A4F' : '#fff'};
        }
    }
`

export const Instalment = () => {

    return (
        <StyledWrapper>
            <StyledContainer>
                <ContainerText>
                    <Heading h1 as='h1'>Finansowanie zabiegów na Raty</Heading>
                    <p>Zastanawiasz się nad zakupem zabiegów, nie masz wystarczających środków ? Skorzystaj z finansowania MediRaty. To takie proste! Gdy koszty przestają być przeszkodą, dużo łatwiej jest zoptymalizować plan zabiegowy. Z myślą o Twoich potrzebach w BODYMED możesz skorzystać z wygodnych rat dostępnych w systemie MediRaty lub odroczyć płatność o 3o dni dzięki PayCode.</p>
                </ContainerText>
                <ContainerInstalments>
                    <ContainerInstalment>
                        <StaticImage
                            src="../../images/mediraty_finansowanie_logo_h.png"
                            alt="Logo BodyMed Group" 
                            title="BodyMed Group" 
                            layout="fixed"
                            height={120}
                        />
                        <ContainerInstalmentText>
                            <p>MediRaty to innowacyjne narzędzie ratalne firmy Medical Finance Group S.A. specjalizujące się w finansowaniu usług w branży medycznej i estetycznej.
                            </p>
                            <p>MediRaty to przede wszystkim:</p>
                            <ContainerListsInstalment>
                                <ul>
                                    <li>gwarancja najniższej raty</li>
                                    <li>prosta i szybka procedura</li>
                                    <li>kwota finansowania do 50 tys. zł</li>
                                </ul>
                                <ol>
                                    <li>Kosmetolog ustala z Tobą indywidualny plan zabiegowy oraz związane z nim koszty.</li>
                                    <li>Konsultant z MediRaty kontaktuje się Tobą telefonicznie, weryfikuje Twoją zdolność kredytową, po czym otrzymujesz propozycje finansowania.</li>
                                    <li>Przyjeżdża do Ciebie kurier w celu podpisania umowy.</li>
                                    <li>Środki przelewane są na konto BODYMED.</li>
                                </ol>
                            </ContainerListsInstalment>
                        </ContainerInstalmentText>
                        <StyledButton>Złóż wniosek</StyledButton>
                    </ContainerInstalment>

                    <ContainerInstalment>
                        <StaticImage
                            src="../../images/pay-plus.jpg"
                            alt="Logo BodyMed Group" 
                            title="BodyMed Group" 
                            layout="fixed"
                            height={120}
                        />
                        <ContainerInstalmentText>
                            <p>PayCode aktywujesz zupełnie  bezpłatnie. Zastępuje on kartę lub gotówkę. Nosząc go w smartfonie, możesz odnawiać jego aktywność co 90 dni i używać do rozliczenia usług w dowolnie wybranej placówce. Po wykonaniu usługi - spłaty dokonujesz w ciągu 30 dni, nie ponosząc żadnych dodatkowych kosztów. Nieużywany kod - nic nie kosztuje.
                            </p>
                            <ol>
                                <li>Kliknij w "ZŁÓŻ WNIOSEK" i wypełnij formularz.</li>
                                <li>Na Twój smartfon przyjdzie SMS z indywidualnym PayCode, który umożliwi zakup wybranych przez Ciebie zabiegów do kwoty 5 000 zł w BODYMED.</li>
                                <li>W gabinecie wybierz formę zapłaty i po zatwierdzeniu na telefonie transakcja będzie zakończona.</li>
                                <li>Środki otrzymasz zupełnie za darmo jeśli zostaną one spłacone w ciągu 30 dni od daty zakupu.</li>
                            </ol>
                        </ContainerInstalmentText>
                        <StyledButton>Złóż wniosek</StyledButton>
                    </ContainerInstalment>
                </ContainerInstalments>
            </StyledContainer>
        </StyledWrapper>
    )
}